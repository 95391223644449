<template>
  <div class="yf-custom-product-card">
    <SfProductCardCustom
      :image="image ? image : ''"
      :link="link"
      :colors="colors"
      :image-width="216"
      :image-height="326"
      :image-alt="imageAlt"
      :image-loading="imageLoading"
      :title="title"
      :regular-price="regularPrice"
      :special-price="specialPrice"
      :omnibus-price="omnibusPrice"
      wishlist-icon="heart"
      :is-big-image="isBigImage"
      :card-index="cardIndex"
      is-in-wishlist-icon="heart_fill"
      :is-in-wishlist="isInWishlist"
      :is-added-to-cart="isAddedToCart"
      :add-to-cart-disabled="addToCartDisabled"
      @click:wishlist="() => {addToWishlistGtm(title, priceGtm, sku, fullPriceGtm, category); $emit('click:wishlist');
      }"
    >
      <template #colors>
        <div class="yf-product-content">
          <SfButton
            :link="link"
            class="sf-button--pure sf-product-card__link"
            data-testid="product-link"
          >
            <span class="sf-product-card__title">
              {{ title }}
            </span>
          </SfButton>
          <SfPrice
            :class="{ 'display-none': !regularPrice }"
            class="sf-product-card__price"
            :regular="regularPrice"
            :special="specialPrice"
          />
          <span
            v-if="omnibusPrice || specialPrice"
            class="sf-product-card__omnibus-price"
          >
            {{ $t('The price was {price}', {price: omnibusPrice ? omnibusPrice : regularPrice}) }}
          </span>
          <SfColorPicker
            v-if="showSwatches"
            :class="{ 'display-none': colors.length === 0 }"
            class="sf-product-card__colors"
            :is-open="true"
            @click:toggle="toggleColorPicker"
          >
            <SfColor
              v-for="(color, i) in colors"
              :key="color.value"
              :color="color.color"
              :selected="color.selected"
              class="sf-product-card__color"
              :class="{ 'display-none': i > 4 }"
              @click="handleSelectedColor(i)"
            />
            <SfBadge
              v-if="showBadge"
              class="sf-product-card__colors-badge color-secondary"
            >
              {{ `+${colors.length - 5}` }}
            </SfBadge>
          </SfColorPicker>
        </div>
      </template>
      <template #title>
        <span />
      </template>
      <template #price>
        <span />
      </template>
      <template #omnibus-price>
        <span />
      </template>
    </SfProductCardCustom>
    <div
      v-if="showPageDownTrigger"
      :id="'page-down-' + pageNumberProduct"
      :class="'page-down page-down-' + pageNumberProduct"
    />
    <div
      v-if="showPageUpTrigger"
      :id="'page-up-' + pageNumberProduct"
      :class="'page-up page-up-' + pageNumberProduct"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import {
  // SfImage,
  // SfLink,
  SfButton,
  SfColorPicker,
  SfColor,
  SfBadge,
  SfPrice,
} from '@storefront-ui/vue';
import {
  getPriceFixedDecimals,
} from '~/helpers/product/productData';
import SfProductCardCustom from './SfProductCardCustom.vue';

export default defineComponent({
  name: 'CustomProductCard',
  components: {
    SfProductCardCustom,
    // SfImage,
    // SfLink,
    SfButton,
    SfColorPicker,
    SfColor,
    SfBadge,
    SfPrice,
  },
  props: {
    sku: {
      type: String,
      required: false,
      default: '',
    },
    priceGtm: {
      type: String,
      required: false,
      default: '0.00',
    },
    fullPriceGtm: {
      type: String,
      required: false,
      default: '0.00',
    },
    category: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: [String, Array],
      required: false,
      default() {
        return '' || [];
      },
    },
    imageAlt: {
      type: String,
      required: false,
      default: '',
    },
    imageLoading: {
      type: [String],
      default: 'lazy',
    },
    colors: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    regularPrice: {
      type: String,
      required: false,
      default: '',
    },
    specialPrice: {
      type: String,
      required: false,
      default: '',
    },
    omnibusPrice: {
      type: String,
      required: false,
      default: '',
    },
    isInWishlist: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      required: false,
      default: true,
    },
    addToCartDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    showSwatches: {
      type: Boolean,
      required: false,
      default: true,
    },
    pageNumber: {
      type: Number,
      required: false,
      default: 0,
    },
    showPageDownTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPageUpTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBigImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(props) {
    const pageNumberProduct = props.pageNumber;
    // const productPriceSpecial = computed(
    //   // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    //   () => productGetters.getPrice(product.value).special ? productGetters.getPrice(product.value).special : 140,
    // );

    return {
      pageNumberProduct,
    };
  },
  data() {
    return {
      openColorPicker: true,
    };
  },
  computed: {
    showBadge() {
      return this.colors.length > 5;
    },
  },
  methods: {
    toggleColorPicker() {
      this.openColorPicker = !this.openColorPicker;
    },
    handleSelectedColor(colorIndex) {
      if (this.colors.length > 0) {
        this.colors.map((color, i) => () => {
          if (colorIndex === i) {
            this.$emit('click:colors', color);
            if (this.isMobile) {
              this.toggleColorPicker();
            }
          }
          return false;
        });
      }
    },
    addToWishlistGtm(name, price, sku, fullPrice, category) {
      if (!this.isInWishlist) {
        const productAddedToWishlist = {
          name,
          price: price && price > 0 ? price : fullPrice,
          product_id: sku,
          quantity: 1,
          discount: price && price > 0 ? getPriceFixedDecimals(fullPrice - price) : '0.00',
          full_price: fullPrice,
          category,
        };
        this.$gtm.push({
          event: 'add_to_wishlist',
          product: productAddedToWishlist,
        });
      }
    },
  },
});
</script>
<style lang="scss">
.yf-custom-product-card {
  .sf-product-card {
    width: 100%;
    max-width: 100%;
    border: unset;
    padding: unset;

    &__title, &__omnibus-price {
      color: #222a24;
      font-family: 'Frank Ruhl Libre';
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      margin-top: 32px;
      margin-bottom: 0;
      height: 36px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__omnibus-price {
      color: #9fa6ad;
    }
    &__add-button {
      display: none;
    }

    &__price {
      justify-content: center;
      align-items: center;
      margin: 10px 0 0 0;
      flex-direction: row-reverse;

      .sf-price__old,
      .sf-price__special {
        font-size: 12px;
        color: #222a24;
        font-weight: 500;
        font-family: 'Spartan';
        margin: 0 20px;
        line-height: 12px;
        @media (max-width: 768px) {
          margin: 0 2px;
        }
      }

      .sf-price__old {
        color: #9fa6ad;
      }
    }

    &__wishlist-icon {
      --icon-height: 28px;
      --icon-width: 28px;
      --product-card-wishlist-icon-top: 13px;
      --product-card-wishlist-icon-right: 15px;
    }

    &__discount-percentage  {
      position: absolute;
      top: 0.9375rem;
      left: 0.9375rem;
      color: #053805;
      font-weight: 500;
      font-family: 'Spartan';
      line-height: 17px;
      font-size: 17px;
      @media (max-width: 768px) {
        line-height: 14px;
        font-size: 14px;
       }
    }

    .sf-product-card__colors-badge,
    &__color {
      margin-left: 4px;
      margin-right: 4px;
      margin-top: 10px;
    }

    &__colors,
    .sf-product-card__colors-badge {
      padding-bottom: 8px;
      --color-picker-position: relative;
      --color-picker-justify-content: flex-start;
      --color-picker-padding: 0;
      //--color-picker-height: 20px;
      @media (min-width: 769px) and (max-width: 1023px) {
        --color-picker-height: 24px;
      }

      .sf-overlay {
        background: white;
      }
    }

    .sf-product-card__colors-badge {
      box-shadow: none;
      padding: 0;
      max-height: 12px;
      max-width: 12px;
    }

    .sf-color-picker__colors {
      justify-content: center;
    }

    .sf-color,
    .sf-product-card__colors-badge {
      --color-height: 12px;
      --color-width: 12px;
      margin: 0 0.2em;
      pointer-events: none;
      font-size: 10px;
      line-height: 10px;
    }
    .sf-price {
      &__regular {
        color: #0b0e0c;
        font-family: 'Spartan';
        font-size: 12px;
        line-height: 1.2em;
        font-weight: 500;
      }
    }
  }

  .sf-image--wrapper {
    width: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .yf-product-content {
    text-align: center;
    background-color: white;
    width: 100%;
    padding-bottom: 23px;
    padding-left: 17px;
    padding-right: 17px;
    min-height: 5rem;
  }
}

@media (max-width: 768.98px) {
  .yf-custom-product-card {
    .sf-product-card {
      --product-card-box-shadow: unset;
      --yf-product-content-transform: translateX(0);
      --yf-product-content-opacity: 1;
      --yf-product-content-visibility: visible;
      --yf-product-content-events: all;
      --product-card-wishlist-icon-opacity: 1;
      &__colors {
        display: none;
      }
      .sf-overlay {
        background: unset;
      }

      .smartphone-only {
        display: none;
      }
      &__title, &__omnibus-price {
        font-size: 12px;
        line-height: 16px;
        margin-top: 20px;
        height: 32px;
      }

      &__omnibus-price {
        margin-top: unset;
      }

      &__wishlist-icon {
        .sf-icon {
          --icon-height: 30px;
          --icon-width: 27px;
        }
      }

      .sf-price__old {
        margin-left: 3px;
        margin-right: 0;
      }

      .sf-price__special {
        margin-left: 0;
        margin-right: 3px;
      }
    }

    .yf-product-content {
      min-height: 4rem;
      padding-bottom: 14px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
</style>
